import {
  createBaseThemeOptions,
  createUnifiedTheme,
  palettes,
} from '@backstage/theme';

export const customLightTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: palettes.light,
    typography: {
      htmlFontSize: 16,
      fontFamily: 'Arial, sans-serif',
      h1: {
        fontSize: 54,
        fontWeight: 700,
        marginBottom: 10,
      },
      h2: {
        fontSize: 40,
        fontWeight: 700,
        marginBottom: 8,
      },
      h3: {
        fontSize: 32,
        fontWeight: 700,
        marginBottom: 6,
      },
      h4: {
        fontWeight: 700,
        fontSize: 28,
        marginBottom: 6,
      },
      h5: {
        fontWeight: 700,
        fontSize: 24,
        marginBottom: 4,
      },
      h6: {
        fontWeight: 700,
        fontSize: 20,
        marginBottom: 2,
      },
    },
    defaultPageTheme: 'home',
  }),
  fontFamily: 'Comic Sans MS',
  defaultPageTheme: 'home',
});

export const customDarkTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.dark,
      background: {
        default: '#121212', // Color oscuro para el fondo principal
        paper: '#1E1E1E', // Fondo para tarjetas u otros elementos
      },
      text: {
        primary: '#ffffff', // Asegura que el texto sea visible
        secondary: '#B0B0B0',
      },
    },
    typography: {
      htmlFontSize: 16,
      fontFamily: 'Arial, sans-serif',
      h1: {
        fontSize: 54,
        fontWeight: 700,
        marginBottom: 10,
      },
      h2: {
        fontSize: 40,
        fontWeight: 700,
        marginBottom: 8,
      },
      h3: {
        fontSize: 32,
        fontWeight: 700,
        marginBottom: 6,
      },
      h4: {
        fontWeight: 700,
        fontSize: 28,
        marginBottom: 6,
      },
      h5: {
        fontWeight: 700,
        fontSize: 24,
        marginBottom: 4,
      },
      h6: {
        fontWeight: 700,
        fontSize: 20,
        marginBottom: 2,
      },

    },
    defaultPageTheme: 'home',
  }),
  components: {
    MuiCardContent: {
      styleOverrides: {
        root: {
          backgroundColor: '#1E1E1E', // Asegura que los Paper también sean oscuros
          color: '#ffffff',
          '& .sc-gFqAkR': {
            backgroundColor: '#1E1E1E', // Cambia el fondo del elemento específico dentro de MuiCardContent
            color: '#ffffff',
          },
          '& table': {
            '& th': {
                backgroundColor: '#333333 !important'
            },
            '& td': {
              backgroundColor: '#0C0C0CFF !important'
            }
          }
        },
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: '#252525', // Color para filas impares
          },
          '&:nth-of-type(even)': {
            backgroundColor: '#1E1E1E', // Color para filas pares
          },
          '&:hover': {
            backgroundColor: '#333333', // Cambia el color al hacer hover
          },
        },
      },
    },
  },
  fontFamily: 'Comic Sans MS',
  defaultPageTheme: 'home',
} as any);
