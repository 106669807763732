import {
    ScmIntegrationsApi,
    scmIntegrationsApiRef,
    ScmAuth,
} from '@backstage/integration-react';
import {
    AnyApiFactory,
    configApiRef,
    createApiFactory,
} from '@backstage/core-plugin-api';
import { grpcDocsApiWidget } from '@backstage/plugin-api-docs-module-protoc-gen-doc';
import {apiDocsConfigRef, defaultDefinitionWidgets} from "@backstage/plugin-api-docs";
import {ApiEntity} from "@backstage/catalog-model";

export const apis: AnyApiFactory[] = [
    createApiFactory({
        api: scmIntegrationsApiRef,
        deps: { configApi: configApiRef },
        factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
    }),
    createApiFactory({
        api: apiDocsConfigRef,
        deps: {},
        factory: () => {
            // load the default widgets
            const definitionWidgets = defaultDefinitionWidgets();
            // add the grpc-docs api widget to the definition widgets
            definitionWidgets.push(grpcDocsApiWidget);
            return {
                getApiDefinitionWidget: (apiEntity: ApiEntity) => {
                    // find the widget for the type of api entity
                    return definitionWidgets.find(d => d.type === apiEntity.spec.type);
                },
            };
        },
    }),
    ScmAuth.createDefaultApiFactory(),
];
